<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-button
            v-b-modal.modal-new
            variant="primary"
          >
            {{ $t('NEW_RECLAMATION_SUBSUBTYPE') }}
          </b-button>
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :sort-options="{
        enabled:false
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        {{ $t(props.column.label) }}
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'status'">
          <b-button
            v-if="!props.row.status"
            variant="gradient-danger"
            @click="updateStatus(props.row.value, true)"
          >
            {{ $t('INACTIVE') }}
          </b-button>
          <b-button
            v-else
            variant="gradient-success"
            @click="updateStatus(props.row.value, false)"
          >
            {{ $t('ACTIVE') }}
          </b-button>
        </span>

        <span v-else-if="props.column.field ==='nameUntranslate'">{{ props.row.name }} </span>

        <!-- Column: Common -->
        <span v-else>
          {{ $t(props.formattedRow[props.column.field]) }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('PAGINATION_SHOWING') }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ $t('PAGINATION_SHOWING_ELEMENT') }} {{ $t('PAGINATION_TOTAL') }} {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="modal-new"
      :title="$t('NEW_RECLAMATION_SUBSUBTYPE')"
      centered
      :ok-title="$t('INSERT_RECLAMATION_TYPE_MODAL')"
      :cancel-title="$t('CANCEL_BUTTON')"
      @ok="submitModal"
    >
      <b-form>
        <b-alert
          :show="errorForNew.length > 0"
          variant="danger"
        >
          <div class="alert-body">
            <span><strong>{{ $t(errorForNew) }}</strong></span>
          </div>
        </b-alert>
        <b-form-group
          :label="$t('RECLAMATION_SUBSUBTYPE')"
        >
          <b-form-input
            v-model="nameFornew"
            :placeholder="$t('RECLAMATION_SUBSUBTYPE')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('STATUS')"
        >
          <v-select
            v-model="statusForNew"
            :options="optionsForNew"
            :clearable="false"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BButton, BFormSelect, BModal, VBModal, BForm, BFormInput, BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BButton,
    BFormSelect,
    BModal,
    BForm,
    BFormInput,
    vSelect,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 25,
      columns: [
        {
          label: 'SUBSUBTYPE_NAME_UNTRANSLATE',
          field: 'nameUntranslate',
        },
        {
          label: 'SUBSUBTYPE_NAME',
          field: 'name',
        },
        {
          label: 'SUBSUBTYPE_STATUS',
          field: 'status',
          width: '50px',
        },
      ],
      nameFornew: '',
      statusForNew: { label: 'ACTIVE', value: true },
      optionsForNew: [
        { label: 'ACTIVE', value: true },
        { label: 'INACTIVE', value: false },
      ],
      errorForNew: '',
    }
  },
  computed: {
    rows() {
      return this.$store.getters['reclamationSubSubType/getSubSubTypes']
    },
  },
  methods: {
    submitModal(bvModalEvt) {
      this.errorForNew = ''
      bvModalEvt.preventDefault()
      if (this.nameFornew !== '') {
        this.$store.dispatch('reclamationSubSubType/insert', { name: this.nameFornew, status: this.statusForNew.value })
          .then(() => {
            this.$bvModal.hide('modal-new')
            this.$swal(
              {
                title: `${this.$i18n.t('RECLAMATION_SUBSUBTYPE_CREATED_POPUP_HEADER')}`,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              },
            ).then(isConfirm => {
              if (isConfirm) {
                this.nameFornew = ''
                this.statusForNew = { label: 'ACTIVE', value: true }
              }
            })
          })
          .catch(() => {
            this.errorForNew = 'INSERT_ERROR'
          })
      } else {
        this.errorForNew = 'EMPTY_FIELD_ERROR'
      }
    },
    updateStatus(id, status) {
      this.$store.dispatch('reclamationSubSubType/patch', { id, data: { status } })
        .then(() => {
          this.$swal(
            {
              title: `${this.$i18n.t('RECLAMATION_SUBSUBTYPE_UPDATED_POPUP_HEADER')}`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            },
          )
        })
    },
  },
}
</script>
