<template>
  <b-overlay>

    <b-row>
      <b-col
        class="mb-2"
        sm="12"
      >
        <h2 class="content-header-title float-left pr-1 mb-0">
          {{ $t('MENU_SETTINGS') }}
        </h2>
      </b-col>
    </b-row>

    <b-row>
      <!-- LEFT BAR -->
      <b-col md="3">

        <!-- Filters' Card -->
        <b-card>
          <b-card-body>
            <!-- Multi Range -->
            <div class="multi-range-price">
              <h6 class="filter-title mt-0 mb-2">
                {{ $t('PARAM_FILTER_HEADER') }}
              </h6>
              <b-form-radio-group
                v-model="selectedParam"
                class="filter-puce"
                stacked
                :options="reclamationParams"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <reclamationTypeList
            v-if="selectedParam === 'reclamationType'"
          />
          <reclamationSubTypeList
            v-if="selectedParam === 'reclamationSubType'"
          />
          <reclamationSubSubTypeList
            v-if="selectedParam === 'reclamationSubSubType'"
          />
        </b-card>
      </b-col>

    </b-row>

  </b-overlay>

</template>

<script>
import {
  BOverlay, BRow, BCol, BCard, BCardBody, BFormRadioGroup,
} from 'bootstrap-vue'

import reclamationTypeList from './parametrage/listReclamationType.vue'
import reclamationSubTypeList from './parametrage/listReclamationSubType.vue'
import reclamationSubSubTypeList from './parametrage/listReclamationSubSubType.vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormRadioGroup,
    reclamationTypeList,
    reclamationSubTypeList,
    reclamationSubSubTypeList,
  },
  data() {
    return {
      data: '',
      selectedParam: '',
      reclamationParams: [
        { text: this.$i18n.t('RECLAMATION_TYPE'), value: 'reclamationType' },
        { text: this.$i18n.t('RECLAMATION_SUBTYPE'), value: 'reclamationSubType' },
        { text: this.$i18n.t('RECLAMATION_SUBSUBTYPE'), value: 'reclamationSubSubType' },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('reclamationType/fetchAll').then(() => {
      this.$store.dispatch('reclamationSubType/fetchAll')
      this.$store.dispatch('reclamationSubSubType/fetchAll')
      this.selectedParam = 'reclamationType'
    })
  },
}
</script>
<style>
.filter-puce .custom-radio {
  margin-bottom: .75rem;
}
</style>
